import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction001 from './Introduction001'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0025 = () => (
  <Wrapper>
    <ColumnH1 label="こんな時に使いたい！時間単位の有給休暇の活用事例と注意事項" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      <img src="/images/column/details/c0025.jpg" alt="キンクラコラム" />
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        有給休暇の本来の趣旨は賃金支払いが保障された状態で心身のリフレッシュを図り、更なる就労意欲の向上にあると考えます。しかし、業種によっては有給休暇の取得が進んでいない場合も散見されます。
        <br />
        そこで、注目を集めている取得方法のひとつに
        <span>時間単位の有給休暇</span>
        が挙げられます。今回は時間単位の有給休暇におけるケーススタディにフォーカスをあて解説してまいります。
      </p>
      <div className="toc">
        <p>目次</p>
        <ol>
          <li>
            <a href="#toc1">時間単位の有給休暇とは</a>
          </li>
          <li>
            <a href="#toc2">時間単位有給休暇活用の事例</a>
          </li>
          <li>
            <a href="#toc3">年度を超えた場合の繰り越し</a>
          </li>
          <li>
            <a href="#toc4">年5日以上の取得義務との関係</a>
          </li>
          <li>
            <a href="#toc5">最後に</a>
          </li>
        </ol>
      </div>
      <div id="toc1">
        <h1>時間単位の有給休暇とは</h1>
      </div>
      <p>
        本来、有給休暇は暦日を単位としての取得を想定しています。しかし、取得が進まない背景を鑑み、労使協定を締結し、
        <span>年5日を上限</span>
        として時間単位で取得することが可能とされています。
        <br />
        5日に満たない日数しか付与されない労働者については、付与される日数の範囲内で協定することとなります。また、時間単位の取得が馴染まない労働者も散見されることから、時間単位での取得が可能な労働者の範囲を定めることも可能です。尚、協定した書面を労働基準監督署に届け出る必要はありません。
      </p>
      <div id="toc2">
        <h1>時間単位有給休暇活用の事例</h1>
      </div>
      <p>
        この章では有事の際の代用としての使い道になり得る時間単位有給休暇制度の取得事例と子育て、共働きの社員が多い場合の取得事例に絞って先行事例を紹介します。
        <br />
        <br />
        勤続期間が長くなればなるほど確率的に遅刻早退の機会は増えるのが通常です。例えば公共交通機関が遅延した場合を想定しましょう。会社によってはそのような「有事」の際は遅れた時間部分を
        <span>「特別有給休暇」</span>
        として通常の有給休暇とは別に賃金を減額しない特例的な休暇を設け、労働者の保護を図るというケースもあります。法律上、特別有給休暇の設置義務はありませんので、通常の時間単位の有給休暇の申請を勧奨する場合もあります。
        <br />
        <br />
        また、共働き世帯が増えていることもあり子供の通う保育園からの呼び出しなどのため、早退するというケースも少なくありません。そのような際に単に早退するのであればノーワークノーペイの原則により賃金支払い義務はなく、同情できるような早退理由であっても賃金計算上は有給休暇ではない限り、欠勤となります。
        <br />
        また、育児介護休業法で規定する<span>「子の看護休暇」</span>
        については、賃金支払い義務はなく、無給としても違法な労務管理とはなりません。子の看護休暇は取得日数も1年度につき5日（子供が2人以上の場合は10日）が法律上の上限となっています。
        <br />
        令和3年1月以降は<span>時間単位の子の看護休暇</span>
        も法律によって導入されていますが、賃金支払い義務がないことから活用が進んでいない背景があります。その点、時間単位の有給休暇も5日の上限があるものの、賃金支払い義務があることから、給与の減額がないというメリットが大きく、活用が進んでいるという声もあります。特に2人以上子供を養育しながら仕事を継続する場合、2人の子供が同時に病気に罹患するとは限りません。
        <br />
        夫婦交代制で早退をしたとしても給与が減額されない時間単位有給休暇の存在は大きいと言えるでしょう。
      </p>
      <Introduction001 />
      <div id="toc3">
        <h1>年度を超えた場合の繰り越し</h1>
      </div>
      <p>
        有給休暇の時効は2020年4月の労働基準法改正後であっても<span>2年</span>
        のままです（残業代は5年（当分の間3年）に延長）。そうなると年度で取得できなかった分を次年度に繰り越すこととなります。当然、日単位のみ繰り越せるということはなく、
        <span>時間分も繰り越しの対象</span>
        です。仮に就業規則で次年度への繰り越しを禁止する条文を設けたとしても法律上無効となります。解釈として可能な限り付与年度に取得するよう努めることとするという趣旨であれば問題ありません。
      </p>
      <div id="toc4">
        <h1>年5日以上の取得義務との関係</h1>
      </div>
      <p>
        2019年の労働基準法改正において、年10日以上有給休暇が付与される場合、
        <span>付与された日から1年以内に5日の取得が義務</span>
        付けられています。労働者の中には積極的に取得する労働者もいれば、様々な事情により取得が進まない労働者もいるでしょう。そこで、会社が取得の希望を聴取し、取得時季を指定することや、労働者自身で時季を指定し取得する場合もあります。年5日取得未達成の場合は法律上30万円以下の罰金となり、未達成者が仮に2人の場合、「一人一罪」となり、2名分の罰則が適用されるという解釈です。
        <br />
        そもそもそのような事態にならないことが重要ですが、仮に半日単位の取得をした場合、時間単位の取得をした場合で異なった解釈となることから確認をしましょう。半日単位の取得をした場合は「0.5日」として5日に含めることは可能ですが、時間単位の場合は5日含めることはできません。
      </p>
      <div id="toc5">
        <h1 className="read">最後に</h1>
      </div>
      <p>
        時間単位の有給休暇はあくまで労働者の自由な意思によって取得するものであり、時間単位の申請を会社が良かれと思い日単位に変更することや、日単位の申請を代替要員が確保できているにも関わらず時間単位に変更することは適正な時季変更権にはあたりませんので、注意が必要です。
        <br />
        また、時間単位の有給休暇が一般化すると、5日の取得義務未達成という事態にもなりかねませんので、定期的に見直しの機会を設けておくことが肝要です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0025
