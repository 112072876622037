import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0025 from '../../../components/molecules/columnDetailInner0025'
import ColumnRelationCards0025 from '../../../components/molecules/columnRelationCards0025'
import UsefulFunctionLayout016 from '../../../components/molecules/usefulFunctionLayout016'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0025 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '時間単位有給導入のポイント',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="こんな時に使いたい！時間単位の有給休暇の活用事例と注意事項"
        description="こんな時に使おう！時間単位有給休暇！年5日を上限として取得することが可能とされる時間有給の活用事例や取得時の注意事項を紹介します。"
        ogUrl="https://kintaicloud.jp/column/details/C0025/"
        ogType="article"
        ogTitle="こんな時に使いたい！時間単位の有給休暇の活用事例と注意事項"
        ogDescription="こんな時に使おう！時間単位有給休暇！年5日を上限として取得することが可能とされる時間有給の活用事例や取得時の注意事項を紹介します。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0025.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0025 />
        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout016 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事 */}
        <ColumnRelationCards0025 />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0025

export const pageQuery = graphql`
  query C0025 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
